import Vue from 'vue';
import VueEvents from 'vue-plugin-events';
import '@/registerServiceWorker.js';
import App from './App.vue';
import router from './router';
import store from './store';
import LoaderService from './plugins/LoaderService';
import ToastService from './plugins/ToastService';
import axios from 'axios';
import Lang from 'lang.js';
import { init as fetchTranslations } from './i18n/translations';
// import compileForLangJS from '@/helpers/compileForLangJS';
import trans from '@/filters/trans';
import transChoice from '@/filters/transChoice';

// Vue.prototype.$i18n = new Lang({
//     messages: compileForLangJS(translations),
//     locale: 'sv-SE',
//     fallback: 'en-US'
// });

// axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'; // Gives CORS error. Maybe add it to backend constanst as valid header?
axios.defaults.headers.common['Accept'] = 'application/json; chartset=UTF-8';
axios.defaults.headers.common['Authorization'] = 'Bearer ' + process.env.VUE_APP_API_TOKEN;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['X-ECKERO'] = 'CARGO';
axios.defaults.headers.common['X-CORE-SESSION-NAME'] = 'ECKERO_CARGO_SESSID';

axios.defaults.baseURL = `${process.env.VUE_APP_API_DOMAIN}${process.env.VUE_APP_API_BASE}`;

axios.defaults.withCredentials = true;
Vue.prototype.$http = axios;

/** Fetch translations before mounting Vue instance */
fetchTranslations().then(translations => {
    Vue.prototype.$i18n = new Lang({
        messages: translations,
        locale: 'sv-SE',
        fallback: 'en-US'
    });

    /** Make $i18n reactive */
    Vue.mixin({
        beforeCreate() {
            Vue.util.defineReactive(this, '$i18n', Vue.prototype.$i18n);
        }
    });

    Vue.filter('trans', trans);
    Vue.filter('transChoice', transChoice);

    Vue.use(VueEvents);
    Vue.use(LoaderService);
    Vue.use(ToastService);

    Vue.config.productionTip = false;

    new Vue({
        router,
        store,
        render: h => h(App)
    }).$mount('#app');
});
