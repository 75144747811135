const Loader = () => import('../components/Loader');

const LoaderService = {
    async install(Vue) {
        this.eventBus = new Vue();

        Vue.component('loader', Loader);

        Vue.prototype.$loading = {
            show() {
                LoaderService.eventBus.$emit('loading', true);
            },

            hide() {
                LoaderService.eventBus.$emit('loading', false);
            },

            reset() {
                LoaderService.eventBus.$emit('reset');
            }
        };
    }
};

export default LoaderService;
