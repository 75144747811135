import { DateTime } from 'luxon';
import currentUser from './currentUser';
import trans from '@/filters/trans';

/**
 * Custom Eckerö Date object
 *
 * Sets date to a specific timezone, can also keep local time
 *
 * @param {string|null} date - The date
 * @param {string} convertTz - Timezone to convert to
 * @param {boolean} keepLocalTime - If original date local time should be kept and just set the timezone
 * @param {string|null} format - Format which the date is in, ex: 2019-08-29, format should be yyyy-MM-dd
 *
 * More info on format symbols https://unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
 */
const EckeroDate = (date = null, convertTz = 'Europe/Stockholm', keepLocalTime = false, format = null) => {
    if (date && !format && date instanceof Date) {
        date = date.toISOString();
    }

    if (date && format) {
        return DateTime.fromFormat(date, format)
            .setZone(convertTz, { keepLocalTime })
            .setLocale(currentUser.getLocale());
    }

    if (date) {
        return DateTime.fromISO(date).setZone(convertTz, { keepLocalTime }).setLocale(currentUser.getLocale());
    }

    return DateTime.local().setZone(convertTz, { keepLocalTime }).setLocale(currentUser.getLocale());
};

const getFormattedDepartures = booking => {
    let bookingRows = booking.rows;
    const keyedBookingRows = {};

    if (!bookingRows.length) {
        return [];
    }

    bookingRows.forEach(bookingRow => {
        const time = bookingRow.departure.time.split(':');
        const startTime = DateTime.fromISO(bookingRow.departure.time).set({
            hours: time[0],
            minutes: time[1]
        });
        const bookingRowKey = startTime.toISO({
            suppressMilliseconds: true,
            suppressSeconds: true,
            includeOffset: false
        });

        if (!keyedBookingRows.hasOwnProperty(bookingRowKey)) {
            keyedBookingRows[bookingRowKey] = {
                dateTime: bookingRowKey,
                date: bookingRow.departure.date,
                time: bookingRow.departure.time,
                route: bookingRow.route,
                routeStrings: {
                    from: trans(`booking.routes.from.${bookingRow.route}`),
                    to: trans(`booking.routes.to.${bookingRow.route}`),
                    full: trans(`booking.routes.full.${bookingRow.route}`)
                },
                rows: [bookingRow]
            };
        } else {
            keyedBookingRows[bookingRowKey].rows.push(bookingRow);
        }
    });

    const formattedBookingRows = [];

    Object.keys(keyedBookingRows)
        .sort()
        .forEach(key => {
            formattedBookingRows.push(keyedBookingRows[key]);
        });

    return formattedBookingRows;
};

const formattedDate = (date, type = 'short') => {
    const d = DateTime.fromISO(date);
    const day = trans(`generic.weekdays.${type}.${d.weekday}`);

    return `${day}, ${date}`;
};

const splitSelectedDate = value => {
    if (!value) {
        return {
            date: null,
            time: null
        };
    }

    if (value.indexOf('T') === -1) {
        return {
            date: value,
            time: null
        };
    }

    const date = value.split('T');

    return {
        date: date[0],
        time: date[1]
    };
};

/** Checks if returnDate is equal or less than 1 hour of outwardDate */
const isTurningFerry = (outwardDep, returnDep) => {
    const diffDateTime = DateTime.fromISO(`${outwardDep.date}T${outwardDep.time}`)
        .plus({
            hours: 4
        })
        .toFormat('yyyy-MM-dd HH:mm');

    return `${returnDep.date} ${returnDep.time}` <= diffDateTime;
};

const getDefaultRouteByCountryCode = code => (['FI', 'AL'].includes(code) ? 'EG' : 'GE');

const todaysDate = DateTime.local().toFormat('yyyy-MM-dd');
const selectedTimePlaceholder = '13:37';
const cargoResourceCodes = ['LST', 'LSTTR'];

export {
    // isDepartureEditable,
    EckeroDate,
    getFormattedDepartures,
    formattedDate,
    splitSelectedDate,
    isTurningFerry,
    getDefaultRouteByCountryCode,
    todaysDate,
    selectedTimePlaceholder,
    cargoResourceCodes
};
