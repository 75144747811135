const defaultNameListState = () => {
    return {
        items: []
    };
};

export default {
    namespaced: true,

    state: defaultNameListState(),

    mutations: {
        UPDATE_ITEMS(state, payload) {
            state.items = payload;
        },

        UPDATE_ITEM(state, payload) {
            state.items = state.items.map(item => {
                if (payload.id !== item.id) {
                    return item;
                }

                return { ...item, payload };
            });
        },
        RESET(state) {
            const defaultState = defaultNameListState();

            state.items = defaultState.items;
        }
    },

    getters: {
        isInvalid(state) {
            return (
                state.items.filter(n => {
                    if (n.firstname === '' && n.lastname === '' && n.dob === '') {
                        return false;
                    }

                    if (n.firstname === '' || n.lastname === '' || n.dob === '') {
                        return true;
                    }

                    if (n.firstname !== '' || n.lastname !== '') {
                        return n.dob === '';
                    }

                    return false;
                }).length > 0
            );
        }
    },

    actions: {
        setItems({ commit }, payload) {
            commit('UPDATE_ITEMS', payload);
        },

        updateItem({ commit }, payload) {
            commit('UPDATE_ITEM', payload);
        },

        reset({ commit }) {
            commit('RESET');
        }
    }
};
