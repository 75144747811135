import Vue from 'vue';
import VueRouter from 'vue-router';
import LoaderService from '../plugins/LoaderService';
import setDocumentTitle from '../helpers/setDocumentTitle';
import currentUser from '../helpers/currentUser';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: 'home' */ '../views/Home'),
        meta: {
            init: true,
            config: null,
            auth: true
        }
    },
    {
        path: '/booking/:step?',
        name: 'booking',
        component: () => import(/* webpackChunkName: 'booking' */ '../views/Booking'),
        props: true,
        meta: {
            init: true,
            config: null,
            auth: true
        }
    },
    {
        path: '/receipt',
        name: 'receipt',
        component: () => import(/* webpackChunkName: 'receipt' */ '../views/Receipt'),
        props: true,
        meta: {
            init: true,
            config: null,
            auth: true
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: 'login' */ '../views/Login')
    },

    /**
     * Fallback
     */
    {
        path: '*',
        name: '404',
        component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFound')
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

/**
 * Check authentication and authorization
 */
router.beforeEach(async (to, from, next) => {
    let nextRoute = {
        replace: false
    };

    LoaderService.eventBus.$emit('show');

    if (to.meta && to.meta.auth) {
        // LoaderService.eventBus.$emit('show');

        try {
            const loggedIn = await currentUser.isLoggedIn();

            if (!loggedIn) {
                nextRoute = {
                    name: 'login',
                    replace: true
                };
            }
        } catch (err) {
            nextRoute = {
                name: 'login',
                replace: true
            };

            console.log('isLoggedIn BeforeEach Route err', err);
        }
    }

    if (to.meta && to.meta.init) {
        // LoaderService.eventBus.$emit('show');

        const initConfig = await currentUser.getInitConfig();

        if (!initConfig) {
            await currentUser.logOut();

            // LoaderService.eventBus.$emit('hide');

            nextRoute = {
                name: 'login',
                replace: true
            };
        } else {
            to.meta.config = initConfig;
        }
    }

    LoaderService.eventBus.$emit('hide');

    next(nextRoute);
});

/**
 * Set document title to the current page
 */
router.afterEach(to => {
    const title = to.meta && typeof to.meta.title === 'function' ? to.meta.title() : null;
    setDocumentTitle(title);
});

export default router;
