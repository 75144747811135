import Vue from 'vue';
// import moment from 'moment';
// import some from 'lodash/some';
// import every from 'lodash/every';
import store from '@/store';
import cargoHelper from '@/helpers/cargo.js';

/**
 * Keeps track of logged in user instance
 */
const currentUser = {
    /** @internal */
    _userData: null,
    /** @internal */
    _locale: 'sv-SE',
    /** @internal */
    _config: null,

    async getUser(fetchData = true) {
        if (fetchData === true && !this._userData) {
            const userResponse = await Vue.prototype.$http
                .get('getAgentUser')
                .then(response => response.data)
                .catch(() => null);

            if (!userResponse || userResponse.code === 105) {
                return null;
                // throw Vue.prototype.$i18n.get('authentication.errors.unauthenticated');
            }

            this.setUser(userResponse);

            cargoHelper.setCoVehicles(userResponse.agency.vehicles || []);
        }

        return this._userData;
    },

    setUser(user) {
        this._userData = user;
        // this.setLocale(user.locale);
    },

    setCoPassengers(passengers) {
        if (!this._userData) {
            return;
        }

        this._userData.agency.namelist = passengers;
        // this.setLocale(user.locale);
    },
    //
    setLocale(locale) {
        this._locale = locale;
        //     moment.locale(locale);
        Vue.prototype.$i18n.setLocale(locale);
        //     Vue.prototype.$http.defaults.headers.common['Accept-Language'] = locale;
    },
    //
    getLocale() {
        return this._locale;
    },

    async logIn(username, password, remember = false) {
        /**
         * Fixed login issues if session timed out
         *
         * @todo create a session checker that logs user out correctly?
         */
        await store.dispatch('booking/resetState');
        await Vue.prototype.$http.post('/resetSession', {
            returnBooking: false
        });
        /** @todo END */

        return Vue.prototype.$http
            .post('/loginAgentUser', {
                username: username,
                password: password,
                remember: remember
            })
            .then(response => {
                if (!response.data || !response.data.user) {
                    throw Vue.prototype.$i18n.get('authentication.errors.invalid_credentials');
                }

                this.setUser(response.data.user);

                return response.data.user;
            });
    },

    async logOut() {
        this._userData = null;
        this._config = null;
        await Vue.prototype.$http.post('logoutAgentUser').catch(() => null);

        try {
            await Vue.prototype.$http.post('resetSession', {
                params: {
                    return_booking: false
                }
            });
        } catch (err) {
            console.log('logoutAgentUser error:', err);
        }

        await store.dispatch('booking/resetState');

        return true;
    },

    async isLoggedIn(fetchFromApi = true) {
        return await this.getUser(fetchFromApi);
    },

    async isLoggedInRequest() {
        try {
            return await Vue.prototype.$http
                .get('isAgentUserLoggedIn')
                .then(response => response.data)
                .catch(() => {
                    return null;
                });
        } catch (err) {
            return false;
        }
    },

    async getInitConfig() {
        if (this._config) {
            return this._config;
        }

        const initConfig = await Vue.prototype.$http.get('initCargo').catch(() => null);
        if (initConfig && initConfig.data.hasOwnProperty('config')) {
            this._config = initConfig.data;
        }

        return this._config;
    },

    async trailerEnabled() {
        const trailerAgents = process.env.VUE_APP_TRAILER_AGENT_NUMBERS.split(',');
        const user = await this.getUser(false);

        return trailerAgents.includes(user.AgentNumber);
    }
};

export default currentUser;
