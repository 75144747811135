import Vue from 'vue';
import currentUser from '@/helpers/currentUser.js';

const cargoHelper = {
    _CoVehicles: [],

    setCoVehicles(payload) {
        this._CoVehicles = payload;
    },

    getCoVehicles(fetch = false) {
        if (fetch || this._CoVehicles.length === 0) {
            return this.fetchCoVehicles();
        }

        return Array.isArray(this._CoVehicles) ? this._CoVehicles : [];
    },

    async fetchCoVehicles() {
        const user = await currentUser.getUser();

        const vehiclesResponse = await Vue.prototype.$http
            .get('getAgentCoVehicles', { agentNumber: user.agentNumber })
            .then(response => response.data)
            .catch(() => null);

        this._CoVehicles = Array.isArray(vehiclesResponse) ? vehiclesResponse : [];

        return this._CoVehicles;
    },

    async addCargoRows(payload) {
        return Vue.prototype.$http.post('/addCargoRow', payload).then(response => response.data);
    },

    /**
     *
     * @param object vehicle
     * @param string route
     * @param array date [yyyy-mm-dd, HH:mm]
     *
     * @returns object
     */
    createObjectForApi(vehicle, route, date, time) {
        return {
            code: vehicle.type,
            route: route,
            date: date,
            time: time,
            dialogs: [
                {
                    length: vehicle.length,
                    licenseNumber: vehicle.license_number,
                    electricalPlugin: vehicle.electrical_plugin,
                    tareWeight: null,
                    netWeight: vehicle.freight_weight,
                    description: vehicle.description
                }
            ]
        };
    },
    /**
     *
     * @param object vehicle
     * @param string route
     * @param array date [yyyy-mm-dd, HH:mm]
     *
     * @returns object
     */
    createObjectToAddAsCoVehicle(vehicle) {
        return {
            code: vehicle.type,
            length: vehicle.length,
            licenseNumber: vehicle.license_number,
            description: vehicle.description
        };
    },

    createVehicleDialog: (code, dialog) => ({
        type: code,
        length: dialog?.length ?? '',
        license_number: dialog.licenseNumber ?? '',
        electrical_plugin: dialog.electricalPlugin,
        freight_weight: dialog.weight.net ? parseFloat(dialog.weight.net).toString() : '0',
        description: dialog.description,
        saveCoVehicle: false
    }),

    async updateCoVehicles(payload) {
        /** If no licenseNumber is filled in, throw error */
        if (payload.filter(v => !v.licenseNumber).length > 0) {
            throw Vue.prototype.$i18n.get('fields.vehicle_license_number_error_empty');
        }

        const response = await Vue.prototype.$http
            .post('/updateAgentCoVehicles', {
                passengers: payload,
                returnData: true
            })
            .then(res => res.data);

        if (!response || response.error) {
            throw this.parseError(response);
        }

        this.setCoVehicles(response);

        return response;
    },

    parseError(response) {
        let result = Vue.prototype.$i18n.get('generic.error_message');

        if (response?.error.includes('LicenseNumber.AlreadyRegistered')) {
            return Vue.prototype.$i18n.get('fields.vehicle_license_number_error_already_exists');
        }

        return result;
    }
};

export default cargoHelper;
