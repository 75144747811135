import Vue from 'vue';
import cargoHelper from '@/helpers/cargo.js';
import currentUser from '@/helpers/currentUser.js';
import {
    todaysDate,
    getDefaultRouteByCountryCode,
    selectedTimePlaceholder,
    isTurningFerry as isTurningFerryHelper
} from '@/helpers/booking';

const defaultBookingState = async () => {
    let defaultRoute = 'GE';

    /** Reset to agents route based on country */
    if (currentUser) {
        const user = await currentUser.getUser(false);

        defaultRoute = getDefaultRouteByCountryCode(user?.agency?.countryCode ?? '');
    }

    return {
        departures: {
            outward_route: defaultRoute,
            outward_date: todaysDate,
            outward_time: selectedTimePlaceholder,
            return_route: null,
            return_date: null,
            return_time: null
        },
        resources: {
            outward: null,
            return: null
        },
        vehicles: {
            outward: {
                type: 'LST',
                length: null,
                license_number: '',
                electrical_plugin: false,
                freight_weight: '0',
                description: '',
                coId: null,
                saveCoVehicle: false
            },
            return: {
                type: 'LST',
                length: null,
                license_number: '',
                electrical_plugin: false,
                freight_weight: '0',
                description: '',
                coId: null,
                saveCoVehicle: false
            }
        }
    };
};
const cargoKeysToCopy = ['length', 'license_number'];

export default {
    namespaced: true,

    state: {
        booking_number: null,
        is_modifying_active_booking: false,
        current_step: 'dates',
        active_booking: defaultBookingState()
    },

    mutations: {
        setCurrentBookingNumber(state, payload) {
            state.booking_number = payload;
        },

        setIsModifyingActiveBooking(state, payload) {
            state.is_active_booking = payload;
        },

        setCurrentStep(state, payload) {
            state.current_step = payload;
        },

        setOutwardRoute(state, payload) {
            state.active_booking.departures.outward_route = payload;
        },

        setOutwardDate(state, payload) {
            state.active_booking.departures.outward_date = payload;

            /** Check if return departure is turning ferry, if so deselect it but keep the date */
            const departures = state.active_booking.departures;
            if (
                departures.return_time &&
                isTurningFerryHelper(
                    { date: payload, time: departures.outward_time },
                    { date: departures.return_date, time: departures.return_time }
                )
            ) {
                /** @todo check if time is needed to be set here aswell */
                state.active_booking.departures.return_date = departures.return_date;
            }
        },

        setOutwardTime(state, payload) {
            state.active_booking.departures.outward_time = payload;

            /** Check if return departure is turning ferry, if so deselect it but keep the date */
            const departures = state.active_booking.departures;
            if (
                departures.return_time &&
                isTurningFerryHelper(
                    { date: departures.outward_date, time: payload },
                    { date: departures.return_date, time: departures.return_time }
                )
            ) {
                state.active_booking.departures.return_date = departures.outward_date;
                state.active_booking.departures.return_time = null;
            }
        },

        setReturnRoute(state, payload) {
            state.active_booking.departures.return_route = payload;
        },

        setReturnDate(state, payload) {
            state.active_booking.departures.return_date = payload;
        },

        setReturnTime(state, payload) {
            state.active_booking.departures.return_time = payload;
        },

        updateVehicleValue(state, { routeDir, key, value }) {
            state.active_booking.vehicles[routeDir][key] = value;
        },

        setVehicle(state, { routeDir, value }) {
            state.active_booking.vehicles[routeDir] = value;
        },

        setResources(state, { routeDir, value }) {
            state.active_booking.resources[routeDir] = value;
        },

        async resetVehiclesOnRoute(state, { route }) {
            const defaultState = await defaultBookingState();
            const dir = route === state.active_booking.departures.outward_route ? 'outward' : 'return';

            state.active_booking.vehicles[dir] = { ...defaultState.vehicles[dir] };
        },

        async resetBookingStateExceptDepartures(state) {
            const defaultState = await defaultBookingState();

            state.current_step = 'dates';
            state.active_booking.vehicles = { ...defaultState.vehicles };
            state.active_booking.resources = { ...defaultState.resources };
        },

        // async resetAfterConfirm(state) {
        //     state.booking_number = null;
        //     state.active_booking = await defaultBookingState();
        // },

        async resetAll(state) {
            state.booking_number = null;
            state.current_step = 'dates';
            state.active_booking = await defaultBookingState();
        }
    },

    getters: {
        bookingNumber(state) {
            return state.booking_number;
        },

        hasReturnRoute(state) {
            return state.active_booking && state.active_booking.departures.return_route !== null;
        },
        outwardDeparture(state) {
            const deps = state.active_booking.departures;

            if (!deps || !deps.outward_route) {
                return {
                    route: null,
                    date: null,
                    time: null
                };
            }

            return {
                route: deps.outward_route,
                date: deps.outward_date,
                time: deps.outward_time
            };
        },

        returnDeparture(state) {
            const deps = state.active_booking.departures;

            if (!deps || !deps.return_route) {
                return {
                    route: null,
                    date: null,
                    time: null
                };
            }

            return {
                route: deps.return_route,
                date: deps.return_date,
                time: deps.return_time
            };
        }
    },

    actions: {
        storeActiveBooking({ commit }, payload) {
            commit('setCurrentBookingNumber', payload.bookingNumber > 1 ? payload.bookingNumber : payload.id);
            commit('setIsModifyingActiveBooking', payload.bookingNumber > 1);

            if (!payload.rows) {
                return;
            }

            if (!payload.dates.outward.date) {
                return;
            }

            const hasReturn = payload.routes.return;

            /** Outward departure */
            commit('setOutwardRoute', payload.routes.outward);
            commit('setOutwardDate', payload.dates.outward.date);
            commit('setOutwardTime', payload.dates.outward.time);

            const outwardCargoRow = payload.rows.find(
                row => row.category === 'cargo' && row.route === payload.routes.outward
            );

            /** Set outward vehicle state */
            if (outwardCargoRow) {
                commit('setVehicle', {
                    routeDir: 'outward',
                    value: cargoHelper.createVehicleDialog(outwardCargoRow.code, outwardCargoRow.dialogs[0])
                });
            }

            /** Return departure */
            if (hasReturn) {
                commit('setReturnRoute', payload.routes.return);
                commit('setReturnDate', payload.dates.return.date);
                commit('setReturnTime', payload.dates.return.time);

                const returnCargoRow = payload.rows.find(
                    row => row.category === 'cargo' && row.route === payload.routes.return
                );

                if (returnCargoRow) {
                    /** Set return vehicle state */
                    commit('setVehicle', {
                        routeDir: 'return',
                        value: cargoHelper.createVehicleDialog(returnCargoRow.code, returnCargoRow.dialogs[0])
                    });
                }
            }
        },

        storeCurrentStep({ commit }, payload) {
            commit('setCurrentStep', payload);
        },

        storeSetVehicle({ commit, getters }, { routeDir, vehicle, force = false }) {
            commit('setVehicle', { routeDir, value: vehicle });

            /** Copy selected values to return */
            if (getters.hasReturnRoute && routeDir === 'outward' && force === true) {
                const copyPayload = { value: vehicle, routeDir: 'return' };

                commit('setVehicle', copyPayload);
            }
        },

        storeUpdateVehicle({ commit, getters }, payload) {
            commit('updateVehicleValue', payload);

            /** Copy selected values to return */
            if (
                getters.hasReturnRoute &&
                payload.routeDir === 'outward' &&
                (payload.force === true || cargoKeysToCopy.includes(payload.key))
            ) {
                const copyPayload = { ...payload, routeDir: 'return' };

                commit('updateVehicleValue', copyPayload);
            }
        },

        async storeSendCargoUpdate({ state }) {
            const departures = state.active_booking.departures;
            const outwardVehicle = state.active_booking.vehicles.outward;
            const cargoRows = [
                cargoHelper.createObjectForApi(
                    outwardVehicle,
                    departures.outward_route,
                    departures.outward_date,
                    departures.outward_time
                )
            ];

            if (departures.return_date) {
                const returnVehicle = state.active_booking.vehicles.return;

                cargoRows.push(
                    cargoHelper.createObjectForApi(
                        returnVehicle,
                        departures.return_route,
                        departures.return_date,
                        departures.return_time
                    )
                );
            }

            let response;

            try {
                response = await cargoHelper.addCargoRows({
                    rows: cargoRows,
                    returnBooking: true
                });
            } catch (err) {
                throw Vue.prototype.$i18n.get('authentication.errors.unauthenticated');
            }

            return response;
        },

        storeSetResources({ commit }, payload) {
            commit('setResources', payload);
        },

        resetBookingStateExceptDepartures({ commit }) {
            commit('resetBookingStateExceptDepartures');
        },

        // resetStateAfterConfirm({ commit }) {
        //     commit('resetAfterConfirm');
        // },

        resetState({ commit }) {
            commit('resetAll');
        }
    }
};
