import axios from 'axios';
// import swedish from './sv-SE';
// import english from './en-US';

// export default {
//     'sv-SE': swedish,
//     'en-US': english
// };

export async function init() {
    const axiosInstance = axios.create({
        baseURL: '/'
    });

    return Promise.all([axiosInstance.get('/languages/en.json'), axiosInstance.get('/languages/sv.json')])
        .then(async languageFiles => {
            const translations = {};
            const en = languageFiles[0].data;
            const sv = languageFiles[1].data;

            /** English */
            Object.keys(en).map(key => {
                translations[`en-US.${key}`] = en[key];
            });

            /** Swedish */
            Object.keys(sv).map(key => {
                translations[`sv-SE.${key}`] = sv[key];
            });

            return translations;
        })
        .catch(err => {
            console.log('Fetch language files error:', err);
        });
}
