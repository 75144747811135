const Toast = () => import('../components/Toast');

const ToastService = {
    install(Vue) {
        this.eventBus = new Vue();

        Vue.component('toast', Toast);

        Vue.prototype.$toast = {
            show(title, message, type, duration) {
                ToastService.eventBus.$emit('show', title, message, type, duration);
            }
        };
    }
};

export default ToastService;
