<template>
    <div id="app">
        <router-view />
        <toast />
        <loader />
        <div class="appUpdateNotification" v-if="showUpdateNotification">
            <div class="-wrapper">
                <span class="-title">
                    {{ 'generic.app_update_title' | trans }}
                </span>
                <span class="-text">
                    {{ 'generic.app_update_text' | trans }}
                </span>
                <button class="bulk-button primary" v-on:click.prevent="refreshOnUpdate">
                    {{ 'generic.app_update_button' | trans }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import currentUser from '@/helpers/currentUser';
import appUpdate from '@/mixins/update.js';

/** Use this to see how long a customer has been on the webapp */
const InitTimer = Date.now();
function initTimerDiff() {
    return Math.abs(InitTimer - Date.now()) / 1000;
}

export default {
    name: 'App',
    mixins: [appUpdate],

    data: () => ({
        showUpdateNotification: false
    }),

    async mounted() {
        this.$events.on('userLogin', this.startSessionPinger);

        this.startSessionPinger();
    },

    watch: {
        /** Check if update notification should be shown or just reload */
        async updateExists(val) {
            if (val === true) {
                const activeSeconds = initTimerDiff();

                this.$loading.show();

                /** If just landed in webapp, do not show notification. Just reload it */
                if (activeSeconds < 5) {
                    this.refreshApp();

                    return;
                }
            }

            this.showUpdateNotification = val;
        }
    },

    methods: {
        /** Ping backend with an Bookit API call once in a while
         * to not get kicked out of current session
         * Currently set to 15 minutes
         */
        startSessionPinger() {
            setInterval(
                async () => {
                    if (await currentUser.isLoggedIn(false)) {
                        currentUser.isLoggedInRequest();
                    }
                },
                1000 * 60 * 15
            );
        },

        refreshOnUpdate() {
            this.$loading.show();
            this.refreshApp();
        }
    }
};
</script>

<style lang="scss">
@use 'sass:math';

@import 'node_modules/wa-eckero-scss/src/variables.scss';
$fontsPath: '../node_modules/wa-eckero-scss/fonts/';
@import 'wa-eckero-scss/src/_fonts.scss';
@import 'node_modules/wa-eckero-scss/src/mixins.scss';
@import 'node_modules/wa-eckero-scss/src/global.scss';

body {
    overflow-y: auto;
}

h3 {
    margin: 1rem 0 0.5rem;
    font-weight: $font-bold;
    text-transform: uppercase;
}

#app {
    position: relative;
    height: inherit;
    margin: 0;
    padding: 0;
    color: $dark;
    background-color: $gray-lightest;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-overflow-scrolling: touch;
    /* smooth scroll iOS */

    .container {
        max-width: $content-width;
        width: 100%;
        margin: 1rem auto;
        padding: 0.5rem 1rem;
    }

    .dropdown,
    .dropdownHybrid {
        padding: 2px;
    }

    .custom-label {
        padding: 2px;

        &.custom-select {
            padding: 0 2px;
        }

        &::after {
            bottom: 2px;
            left: 2px;
            width: calc(100% - 4px);
        }
    }

    input,
    select {
        max-width: 100%;
        font-family: $font-family;
    }

    .bulk-button {
        min-width: 5rem;
    }
}

.appLogo {
    display: flex;
    max-width: 20rem;
    margin: 0 auto 3rem;
}

.appUpdateNotification {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: $light;
    z-index: 99;

    .-wrapper {
        position: absolute;
        display: flex;
        flex-direction: column;
        flex: 1 1 0%;
        max-width: math.div($content-width, 2);
    }

    .-title {
        color: $primary;
        font-size: 2rem;
        font-weight: $font-semibold;
    }

    .-text {
        margin: 0.5rem auto 1rem;
        font-size: 1.2rem;
    }
}
</style>
