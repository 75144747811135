import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import booking from './modules/booking';
import namelist from './modules/namelist';

Vue.use(Vuex);

const vuexLocalIncludes = [
    'booking/setCurrentBookingNumber',
    'booking/setCurrentStep',
    'booking/setOutwardRoute',
    'booking/setOutwardDate',
    'booking/setReturnRoute',
    'booking/setReturnDate',
    'booking/updateVehicleValue',
    'booking/setVehicle',
    'booking/setResources',
    'booking/resetAll'
];

const vuexLocal = new VuexPersistence({
    key: 'eckero-cargo-booking',
    storage: window.localStorage,
    filter: mutation => vuexLocalIncludes.includes(mutation.type)
});

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        booking,
        namelist
    },
    plugins: [vuexLocal.plugin]
});
